import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { Button } from '../../components/ui/button';
import { Globe, Languages } from 'lucide-react';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || localStorage.getItem('i18nextLng') || 'en';

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang); // Persist selected language
  };

  const languageMap = {
    en: 'English',
    fr: 'Français',
    tg: 'Tagalog',
    kr: '한국어',
    hn: 'हिंदी',
  };

  return (
    <DropdownMenu className="border-none ">
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" className="px-2 font-normal">
          <Languages className="h-[1.0rem] w-[1.0rem]" />
          <Globe className="h-[1.0rem] w-[1.0rem]" />
          <span className="ml-2">{languageMap[currentLanguage]}</span>
          <span className="sr-only">Toggle language</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {Object.entries(languageMap).map(([key, label]) => (
          <DropdownMenuItem
            key={key}
            onClick={() => changeLanguage(key)}
            className={key === currentLanguage ? 'font-bold' : ''} // Highlight selected language
          >
            {label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LanguageSwitcher;
