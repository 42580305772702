import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../ui/accordion';
import MarkDownView from '../../MarkDownView';
import { Button } from '../../ui/button';
import { useCourse } from '../../../context/useCourseContext';
import { Link } from 'react-router-dom';
import { ScrollArea } from '../../ui/scroll-area';

function KeyNotes() {
  const { isBro, course, getKeynotes } = useCourse();
  const [loading, setLoading] = useState(false);
  return (
    <ScrollArea className="h-full">
      {course?.keyNotes?.length > 0 ? (
        <div className="flex flex-wrap gap-4 p-2">
          {course.keyNotes.map((notes, index) => (
            <div
              key={index}
              className="p-2 px-4 border-2 border-dashed shadow-md rounded-lg hover:shadow-lg transition-all">
              <Accordion type="single" collapsible>
                <AccordionItem value={notes?._id}>
                  <AccordionTrigger className="text-left text-sm font-semibold">{notes?.title}</AccordionTrigger>
                  <AccordionContent className="mt-2">
                    <MarkDownView>{notes?.content}</MarkDownView>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center rounded-lg border border-dashed m-2 shadow-sm p-2 sm:p-20">
          <div className="flex flex-col items-center gap-1 text-center h-full">
            <h3 className="text-2xl font-bold tracking-tight">
              Glossary / Revision notes
              <div className="h-1 mx-auto bg-gray-200 w-28 opacity-75 my-4 rounded"></div>
            </h3>
            <div className="flex flex-col items-center justify-center">
              <span className="text-sm text-muted-foreground text-left">
                Generate a comprehensive glossary to enhance your learning experience. It includes:
                <ul className="list-disc list-inside mt-2">
                  <li>Important definitions and formulas</li>
                  <li>Essential concepts</li>
                  <li>Key topics to remember</li>
                  <li>Important questions</li>
                  <li>Critical dates and events</li>
                  <li>Notable figures and their contributions</li>
                </ul>
              </span>
            </div>
            {!isBro ? (
              <Button
                onClick={() => {
                  setLoading(true);
                  getKeynotes(course._id, course.title, course.language);
                }}
                disabled={loading}
                className="my-2">
                Generate
              </Button>
            ) : (
              <div className="text-center mt-4">
                <p className="mb-2 text-muted-foreground">Upgrade to premium for exclusive features and tools.</p>
                <Link to={'/pricing'}>
                  <Button size="sm">
                    <Link to={'/pricing'}>Get Prime</Link>
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </ScrollArea>
  );
}

export default KeyNotes;
