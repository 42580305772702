import React from 'react';
import { ScrollArea } from '../components/ui/scroll-area';
import { Header, Main, AboutUs, Pricing, Faq, Footer } from '../components/landing';

function SignIn() {
  return (
    <div>
      <ScrollArea className="h-screen bg-pastel-white">
        <div className="sm:container h-full">
          <Header />
          <Main />
          <AboutUs />
          {/* <Pricing /> */}
          <Faq />
          <Footer />
        </div>
      </ScrollArea>
    </div>
  );
}

export default SignIn;
