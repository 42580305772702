import Axios from '../../services/axios';
import { Link } from 'react-router-dom';
import { buttonVariants } from '../ui/button';
import { Skeleton } from '../ui/skeleton';
import CourseCard from '../CourseCard';
import { useUser } from '@clerk/clerk-react';
import { Features, CreateCourse } from '.';
import { useQuery } from '@tanstack/react-query';

function RecentlyViewed() {
  const { user } = useUser();

  const getCourses = async () => {
    const res = await Axios.get(`/course/recent/${user.id}`);
    return res.data?.data?.recentlyViewed || [];
  };

  const { data, isLoading } = useQuery({
    queryKey: ['recently viewed', user.id],
    queryFn: getCourses,
    enabled: !!user.id,
    staleTime: 60000,
  });

  if (isLoading)
    return (
      <>
        <div className="px-1">
          <div className="flex items-center justify-between">
            <h2 className="font-bold text-lg">Recently Viewed</h2>
            <Link to="/courses" className={buttonVariants({ variant: 'outline' })}>
              View all
            </Link>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-4">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="space-y-2">
                <Skeleton className="h-80 w-full rounded-lg" />
              </div>
            ))}
          </div>
        </div>
      </>
    );

  if (data.length < 1 && !isLoading)
    return (
      <div className="flex mt-4">
        <Features />
        <CreateCourse />
      </div>
    );

  return (
    <div className="px-1">
      <div className="flex items-center justify-between">
        <h2 className="font-bold text-lg">Recently Viewed</h2>
        <Link to="/courses" state={{ data }} className={buttonVariants({ variant: 'outline' })}>
          View all
        </Link>
      </div>
      {data.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-4">
          {data?.map(
            (course, index) =>
              ((window.innerWidth < 768 && index < 2) || // for small screens
                (window.innerWidth >= 768 && window.innerWidth < 1024 && index < 3) || // for medium screens
                (window.innerWidth >= 1024 && index < 4)) && (
                <CourseCard key={index} course={course.course} viewed={course.viewedAt} />
              )
          )}
        </div>
      )}
    </div>
  );
}

export default RecentlyViewed;
