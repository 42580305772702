import React from 'react';
import { Switch } from '../../ui/switch';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../ui/card';

const ChatSettings = () => {
  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Chat Settings</CardTitle>
        <CardDescription>Configure chat preferences and permissions</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="space-y-0.5">
            <div className="font-medium">Contextual AI Assistant</div>
            <div className="text-sm text-gray-500">Enable AI to respond using training data</div>
          </div>
          <Switch disabled />
        </div>
        <div className="flex items-center justify-between">
          <div className="space-y-0.5">
            <div className="font-medium">Guest Chat</div>
            <div className="text-sm text-gray-500">Allow guests to participate in chat</div>
          </div>
          <Switch disabled />
        </div>
        <div className="flex items-center justify-between">
          <div className="space-y-0.5">
            <div className="font-medium">NSFW Content</div>
            <div className="text-sm text-gray-500">Allow NSFW content in chat</div>
          </div>
          <Switch disabled />
        </div>
      </CardContent>
    </Card>
  );
};

export default ChatSettings;
