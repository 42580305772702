import React, { useState, useEffect } from 'react';
import Axios from '../../services/axios';
import { Link } from 'react-router-dom';
import { buttonVariants } from '../ui/button';
import CourseCard from '../CourseCard';
import { useQuery } from '@tanstack/react-query';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { ArrowUpLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function AdminCourses() {
  const [uni, setUni] = useState('cu');
  const [course, setCourse] = useState('cse');
  const [sem, setSem] = useState(localStorage.getItem('sem') || 'eight');
  const { t } = useTranslation();
  const appVersion = JSON.parse(process.env.REACT_APP_VERSION);

  const fetchCourses = async () => {
    const res = await Axios.get(`/collection/${sem}`);
    return res.data.data?.courses || [];
  };

  const { data } = useQuery({
    queryKey: ['courses', sem],
    queryFn: fetchCourses,
    staleTime: 60000,
  });

  useEffect(() => {
    localStorage.setItem('sem', sem);
  }, [sem]);

  return (
    <div className="relative text-center">
      <div className="flex justify-between pb-4 p-1 -mx-1">
        <div className="flex flex-col md:flex-row justify-start gap-3 md:py-2 px-1">
          {appVersion.brand === 'Brogrammers' ? (
            <>
              <Select defaultValue={uni} onValueChange={(value) => setUni(value)}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select University" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>University</SelectLabel>
                    <SelectItem value="lh">LeHigh University</SelectItem>
                    <SelectItem value="um">University of MaryLand</SelectItem>
                    <SelectItem value="cu">Chandigarh University</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>

              <Select defaultValue={course} onValueChange={(value) => setCourse(value)}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select your course" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Course</SelectLabel>
                    <SelectItem value="cse">CSE</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>

              <Select value={sem} onValueChange={(value) => setSem(value)}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select a semester" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Semester</SelectLabel>
                    {/* <SelectItem value="one">One</SelectItem>
                <SelectItem value="three">Three</SelectItem>
                <SelectItem value="five">Five</SelectItem>
                <SelectItem value="seven">Seven</SelectItem> */}
                    <SelectItem value="two">Two</SelectItem>
                    <SelectItem value="four">Foure</SelectItem>
                    <SelectItem value="six">Six</SelectItem>
                    <SelectItem value="eight">Eight</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </>
          ) : (
            <Select onValueChange={(value) => setSem(value)}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a module" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Moduke</SelectLabel>
                  <SelectItem value="math">Math</SelectItem>
                  <SelectItem value="sc">Science</SelectItem>
                  <SelectItem value="dev">Development</SelectItem>
                  <SelectItem value="com">Commerce</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
        </div>
        <Link to="/courses" state={{ data }} className={buttonVariants({ variant: 'outline' })}>
          View all
        </Link>
      </div>

      {data?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-4">
          {data?.map(
            (course, index) =>
              ((window.innerWidth < 768 && index < 2) ||
                (window.innerWidth >= 768 && window.innerWidth < 1024 && index < 3) || // for medium screens
                (window.innerWidth >= 1024 && index < 4)) && (
                <CourseCard key={index} course={course} />
              )
          )}
        </div>
      ) : (
        <div className="py-8 border-2 border-dashed rounded-lg">
          <div className="mx-4 sm:mx-auto max-w-3xl">
            <p className="mt-2 text-lg sm:text-2xl font-semibold flex items-center justify-center">
              <ArrowUpLeft className="mr-2" /> {t('community_Course.selectSemester')}
            </p>
            <span className="block text-center mt-3 text-base sm:text-lg">
              {t('community_Course.maintainedCourses')}
            </span>
            <div className="flex justify-center mt-4 px-4 sm:px-0">
              <div className="text-left">
                <ol className="list-disc list-inside text-base sm:text-lg">
                  <li>{t('community_Course.features.docChat')}</li>
                  <li>{t('community_Course.features.topicExplanation')}</li>
                  <li>{t('community_Course.features.videoSuggestions')}</li>
                  <li>{t('community_Course.features.handwrittenNotes')}</li>
                  <li>{t('community_Course.features.glossarySummary')}</li>
                  <li>{t('community_Course.features.flashcardsQuizzes')}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(AdminCourses);
