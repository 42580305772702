import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { axios } from '../../../services';
import { useCourse } from '../../../context/useCourseContext';
import { ScrollArea } from '../../ui/scroll-area';
import QuizItem from './QuizItem';
import CreateQuiz from './CreateQuiz';

function Quiz({ course }) {
  const { isAdmin } = useCourse();

  const {
    data: quizes = [],
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['quizes', course._id],
    queryFn: async () => {
      const { data } = await axios.get(`/practice/quiz/${course._id}`);
      return data.data;
    },
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  if (isLoading) return <p>Loading quizzes...</p>;
  if (error) return <p>Error loading quizzes: {error.message}</p>;

  return (
    <ScrollArea className="h-full">
      <div className="space-y-8">
        {isAdmin && <CreateQuiz course={course} refetch={refetch} />}
        <div className="grid grid-cols sm:grid-cols-2 md:grid-cols-3 px-1 gap-2 pb-10">
          {quizes.map((quiz) => (
            <div className="p-2" key={quiz._id}>
              <QuizItem quiz={quiz} />
            </div>
          ))}
        </div>
      </div>
    </ScrollArea>
  );
}

export default Quiz;
