import React from 'react';
import { SignOutButton, UserButton, useUser } from '@clerk/clerk-react';
import { LogOut } from 'lucide-react';

function UserInfo({ expanded }) {
  const { user } = useUser();
  return (
    <div className={`border-t border-gray-500 flex p-2 ${!expanded && 'justify-center'}`}>
      <UserButton afterSignOutUrl="/" />
      <div
        className={`
                flex justify-between items-center
                overflow-hidden transition-all ${expanded ? 'w-40 ml-3' : 'w-0'}
            `}>
        <div className="leading-4 overflow-hidden">
          <h4 className="font-semibold text-sm truncate">{user?.username}</h4>
          <span className="text-sm text-muted-foreground font-semibold truncate">
            {user?.fullName}
          </span>
        </div>

        <SignOutButton redirectUrl="/">
          <div className="p-2 hover:text-white cursor-pointer">
            <LogOut strokeWidth={2} size={16} />
          </div>
        </SignOutButton>
      </div>
    </div>
  );
}

export default UserInfo;
