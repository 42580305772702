import React, { useMemo } from 'react';
import { Main, RecentlyViewed, AdminCourses } from '../components/home';
import { ScrollArea } from '../components/ui/scroll-area';

const Home = () => {
  const recentlyViewedComponent = useMemo(() => <RecentlyViewed />, []);
  const myCoursesComponent = useMemo(() => <AdminCourses />, []);

  return (
    <>
      <ScrollArea className="flex-1">
        <div className="container pb-8 -mt-10 pt-10">
          <div className="flex flex-col justify-around min-h-screen py-4 gap-16">
            {recentlyViewedComponent}
            {myCoursesComponent}
          </div>
        </div>
      </ScrollArea>
    </>
  );
};

export default React.memo(Home);
