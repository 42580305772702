import React, { useEffect } from 'react';
import { SignInButton, SignUpButton } from '@clerk/clerk-react';
import { useLocation } from 'react-router-dom';
import backgroundImage from '../../assets/images/home.webp';
import { useTranslation } from 'react-i18next';
import '../../styles/style.css';
import axios from 'axios';

function Main() {
  const location = useLocation();
  const originalRequestUrl = location.pathname + location.search;
  const { t } = useTranslation();

  return (
    <div
      className="flex-1 min-h-[750px] bg-cover bg-center flex flex-col justify-center items-center text-center rounded-3xl"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        border: '2px solid rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(10px)',
      }}>
      <main className="p-4 mt-4 sm:mt-0 text-white flex flex-col items-center">
        <h1 className="custom-font max-w-xl">{t('landing.title')}</h1>
        <h2 className="text-2xl sm:text-4xl font-bold mb-4 p-2 max-w-xl">
          {t('landing.headline')}
        </h2>
        <p className="mb-4 custom-font max-w-xl">{t('landing.description')}</p>

        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <SignUpButton
            mode="modal"
            fallbackRedirectUrl={originalRequestUrl}
            className="px-4 py-2 sm:px-6 sm:py-3 bg-zinc-800 text-white rounded-full hover:bg-zinc-700 font-semibold transition">
            {t('landing.buttons.startJourney')}
          </SignUpButton>
          <SignInButton
            mode="modal"
            fallbackRedirectUrl={originalRequestUrl}
            className="px-4 py-2 sm:px-6 sm:py-3 bg-white text-black rounded-full hover:bg-gray-300 font-semibold transition">
            {t('landing.buttons.signIn')}
          </SignInButton>
        </div>
      </main>
    </div>
  );
}

export default Main;
