import React from 'react';
import { useTranslation } from 'react-i18next';
import SideBar from './SideBar';
import SidebarItem from './components/SidebarItem';
import TopBar from './TopBar';
import { CourseContextProvider } from '../context/useCourseContext';
import { ThemeProvider } from './theme-provider';
import { BookOpen, Briefcase, LifeBuoy, Home, Clipboard, GitGraph, LayoutList, Blocks } from 'lucide-react';

const appVersion = JSON.parse(process.env.REACT_APP_VERSION);

const Layout = ({ children }) => {
  const { t } = useTranslation();

  return (
    <CourseContextProvider>
      <ThemeProvider defaultTheme="light">
        <div className="h-screen flex">
          <SideBar>
            <SidebarItem icon={<Home size={18} />} text={t('sidebar.home')} to="/home" />
            <SidebarItem icon={<BookOpen size={18} />} text={t('sidebar.courses')} to="/courses" />
            {/* <SidebarItem icon={<Clipboard size={18} />} text={t('sidebar.quiz')} to="/quiz" /> */}
            {appVersion?.brand === 'Brogrammers' && (
              <>
                <SidebarItem icon={<LayoutList size={18} />} text={t('sidebar.algoSheet')} to="/dsa" />
                {/* <SidebarItem icon={<GitGraph size={18} />} text={t('sidebar.dsa')} to="/dsa" /> */}
                <SidebarItem icon={<Briefcase size={18} />} text={t('sidebar.stories')} to="/blogs" />
                <SidebarItem icon={<Blocks size={18} />} text={t('sidebar.baskets')} to="/dev" />
              </>
            )}
            <SidebarItem icon={<LifeBuoy size={18} />} text={t('sidebar.help')} to="/help" />
          </SideBar>

          <div className="flex-1 flex flex-col h-screen">
            <TopBar />
            {children}
            <div className="animated-gradient"></div>
          </div>
        </div>
      </ThemeProvider>
    </CourseContextProvider>
  );
};

export default Layout;
