import React from 'react';

function LCLogo() {
  return (
    <svg width="23" height="23" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_49_338)">
        <path
          d="M23.8334 16.2886C23.8334 15.4848 23.2224 14.8315 22.4684 14.8315H11.5657C10.8117 14.8315 10.2007 15.4859 10.2007 16.2886C10.2007 17.0925 10.8117 17.7468 11.5657 17.7468H22.4684C23.2224 17.7468 23.8334 17.0935 23.8334 16.2886Z"
          fill="#B3B1B0"></path>
        <path
          d="M23.8334 16.2886H10.2007C10.2007 17.0924 10.8117 17.7467 11.5657 17.7467H22.4684C23.2224 17.7467 23.8334 17.0935 23.8334 16.2886Z"
          fill="#9C9A99"></path>
        <path
          d="M5.17923 16.2886H5.12939C5.14564 17.0859 5.47064 17.8854 6.10114 18.5289C7.51923 19.9762 8.96006 21.3997 10.3911 22.8341L10.6121 23.0486C11.1202 23.3768 11.1246 24.4027 10.8093 24.8588C10.4735 25.3452 10.0401 25.6486 9.43239 25.6486C9.41073 25.6486 9.38798 25.6486 9.36523 25.6475C9.14856 25.6399 8.97089 25.5532 8.79106 25.4514C8.75314 25.4297 8.71523 25.4081 8.67839 25.3842C9.70973 26.2964 11.0726 26.7373 12.5654 26.7373C14.1731 26.7373 15.6312 26.1837 16.6864 25.1188L19.4911 22.2621C20.0436 21.7052 20.0241 20.7833 19.4489 20.2037C19.1542 19.9058 18.7696 19.7563 18.3894 19.7563C18.0297 19.7563 17.6755 19.8907 17.4079 20.1615L14.5089 22.9857C14.0084 23.4917 13.3151 23.7029 12.5502 23.7029C11.7854 23.7029 11.0921 23.4917 10.5894 22.9857L5.93431 18.2592C5.44248 17.763 5.18898 17.0437 5.17923 16.2886Z"
          fill="#C98F1B"></path>
        <path
          d="M5.12939 16.2886H2.07764C2.08414 17.8973 2.72222 19.479 3.96155 20.7313C5.39589 22.1787 6.83997 23.6162 8.27755 25.0603C8.39997 25.1838 8.53322 25.2932 8.67839 25.3853C8.71522 25.4081 8.75205 25.4308 8.79105 25.4525C8.97089 25.5543 9.14855 25.641 9.36522 25.6486C9.38797 25.6497 9.40964 25.6497 9.43239 25.6497C10.0401 25.6497 10.4735 25.3463 10.8093 24.8599C11.1246 24.4038 11.1191 23.3779 10.6121 23.0497L10.3911 22.8352C8.96005 21.4008 7.51814 19.9773 6.10114 18.53C5.47064 17.8865 5.14564 17.0859 5.12939 16.2886Z"
          fill="#060605"></path>
        <path
          d="M3.77309 20.44L8.44442 25.1644C9.49742 26.225 10.9556 26.7374 12.5654 26.7374C14.1731 26.7374 15.6313 26.1838 16.6864 25.1189L19.4912 22.2621C20.0437 21.7053 20.0242 20.7834 19.4489 20.2038C18.8748 19.6242 17.9593 19.6047 17.4079 20.1615L14.5089 22.9858C14.0084 23.4917 13.3151 23.703 12.5503 23.703C11.7854 23.703 11.0921 23.4917 10.5894 22.9858L5.93434 18.2592C5.43275 17.7522 5.17925 17.0134 5.17925 16.241C5.17925 15.4685 5.43275 14.7709 5.93434 14.265L10.5764 9.51995C11.0791 9.01295 11.7854 8.8212 12.5503 8.8212C13.3162 8.8212 14.0095 9.03245 14.5089 9.53837L17.4079 12.3615C17.9604 12.9195 18.8748 12.9 19.45 12.3204C20.0253 11.7397 20.0448 10.8189 19.4923 10.261L16.6875 7.40637C15.9855 6.70653 15.0939 6.19737 14.0962 5.96554L14.0604 5.95903L16.7113 3.24637C17.266 2.68953 17.2465 1.76653 16.6713 1.18695C16.0971 0.607368 15.1806 0.587868 14.627 1.14578L3.77309 12.0864C2.71792 13.1502 2.1665 14.6192 2.1665 16.242C2.1665 17.8649 2.71792 19.3772 3.77309 20.44Z"
          fill="#E7A41F"></path>
        <path
          d="M8.79117 25.4525C8.6005 25.3474 8.4315 25.2153 8.27767 25.0614C6.84008 23.6174 5.396 22.1798 3.96167 20.7325C1.808 18.556 1.46783 15.3851 3.11125 12.8025C3.36475 12.4016 3.68433 12.0506 4.02017 11.7148C7.4955 8.23295 10.9698 4.74895 14.4451 1.26712C15.1222 0.588949 16.0668 0.560782 16.6973 1.19562C17.3029 1.80445 17.2683 2.77187 16.6139 3.4327C15.7798 4.27337 14.9456 5.11187 14.1114 5.95145C14.067 6.08362 13.9598 6.16812 13.8666 6.26237C12.9317 7.21245 11.9697 8.13653 11.0499 9.09962C10.9297 9.2242 10.7661 9.30112 10.6469 9.43003C9.10533 10.9716 7.54642 12.4959 6.0265 14.058C4.79583 15.3223 4.83917 17.2409 6.10017 18.53C7.51825 19.9774 8.95908 21.4009 10.3902 22.8352L10.6112 23.0497C11.1193 23.378 11.1236 24.4039 10.8083 24.86C10.4606 25.3648 10.0078 25.6725 9.36425 25.6486C9.14867 25.64 8.971 25.5533 8.79117 25.4525Z"
          fill="#070706"></path>
        <path
          d="M14.1062 5.96547C14.0585 6.09005 13.9567 6.1713 13.8667 6.26122C12.9318 7.2113 11.9698 8.13539 11.0501 9.09847C10.9298 9.22305 10.7662 9.29997 10.6471 9.42889C9.10549 10.9705 7.54658 12.4947 6.02666 14.0569C5.39833 14.7026 5.10258 15.5183 5.13074 16.3341H5.18058C5.17949 16.3026 5.17841 16.2712 5.17841 16.2398C5.17841 15.4674 5.43191 14.7697 5.93349 14.2638L10.5756 9.5188C11.0782 9.0118 11.7846 8.82005 12.5494 8.82005C13.3153 8.82005 14.0087 9.0313 14.5081 9.53722L17.4071 12.3604C17.6757 12.6323 18.0311 12.7666 18.3907 12.7666C18.7699 12.7666 19.1545 12.6171 19.4492 12.3192C20.0244 11.7386 20.0439 10.8177 19.4914 10.2598L16.6867 7.40522C15.9868 6.70647 15.0996 6.19839 14.1062 5.96547ZM15.6001 0.737305C15.3899 0.738388 15.1819 0.786055 14.9912 0.880305C15.1895 0.790388 15.3964 0.742721 15.6001 0.737305Z"
          fill="#EAB03C"></path>
        <path
          d="M15.6347 0.737305C15.6239 0.737305 15.612 0.737305 15.6011 0.737305C15.3964 0.741638 15.1906 0.790388 14.9923 0.880305C14.7995 0.968055 14.6142 1.09697 14.4463 1.26597C10.971 4.7478 7.49564 8.2318 4.02139 11.7136C3.68556 12.0495 3.36706 12.4005 3.11247 12.8013C2.40614 13.9117 2.06597 15.1316 2.07897 16.3351H5.13181C5.10364 15.5194 5.40047 14.7036 6.02772 14.058C7.54656 12.4958 9.10656 10.9716 10.6481 9.42997C10.7684 9.30105 10.9309 9.22522 11.0511 9.09955C11.9709 8.13647 12.9329 7.21239 13.8678 6.2623C13.9577 6.1713 14.0596 6.09005 14.1072 5.96655C14.1094 5.96114 14.1105 5.9568 14.1126 5.95139C14.9468 5.1118 15.781 4.2733 16.6151 3.43264C17.2695 2.7718 17.3052 1.80439 16.6986 1.19555C16.3941 0.888971 16.0182 0.737305 15.6347 0.737305Z"
          fill="#272726"></path>
      </g>
      <defs>
        <clipPath id="clip0_49_338">
          <rect width="26" height="26" fill="white" transform="translate(0 0.737305)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LCLogo;
