import React from 'react';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Label } from '../../components/ui/label';
import { ScrollArea } from '../../components/ui/scroll-area';
import { AvatarComponent } from '../../components/readBlog';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from '../../components/ui/card';
import Footer from './Footer';
import { Github, Linkedin, Youtube } from 'lucide-react';

function Help({ isStandalone }) {
  const [result, setResult] = React.useState('');

  const appVersion = JSON.parse(process.env.REACT_APP_VERSION);

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult('Sending....');
    const formData = new FormData(event.target);

    formData.append('access_key', 'e6cbd6b1-19b6-4068-97e9-52731db468e5');

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult('Form Submitted Successfully');
      event.target.reset();
    } else {
      console.log('Error', data);
      setResult(data.message);
    }
  };

  return (
    <ScrollArea className={`${isStandalone ? 'h-screen' : 'h-full'}`}>
      <div className="p-2 md:container md:p-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-5xl md:text-8xl font-bold pb-10">Help & Support</h1>

          <section className="mb-12">
            {/* <Card className="p-3 rounded-lg border-dashed border-2 bg-muted/40 mb-8">
              <CardContent>
                <div className="flex items-center space-x-4 mb-4">
                  <AvatarComponent
                    imageUrl={
                      'https://vinit-jains-bucket.s3.ap-south-1.amazonaws.com/profile-pic1.png'
                    }
                  />
                  <CardTitle className="text-lg font-semibold">Hey, I’m Vinit Jain.</CardTitle>
                </div>
                <CardDescription className="text-sm text-primary">
                  Full Stack genAI developer | Building Brogrammers | Co-founder: Brofit | SIH 2023
                  Winner
                </CardDescription>
                <p>Building brogrammers, the most powerful AI powered learning platform.</p>
              </CardContent>
              <CardFooter className="flex space-x-1 items-center">
                <button
                  className="rounded-md"
                  onClick={() => window.open('https://buymeacoffee.com/parakhvini5', '_blank')}>
                  <img
                    src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                    alt="Buy Me A Coffee"
                    width={135}
                  />
                </button>
                <Button variant="ghost" size="sm" asChild>
                  <a href="https://github.com/dashingvinit">
                    <Github />
                  </a>
                </Button>
                <Button variant="ghost" size="sm" asChild>
                  <a href="https://www.linkedin.com/in/vinit-p-jain-327444178/">
                    <Linkedin />
                  </a>
                </Button>
                <Button variant="ghost" size="sm" asChild>
                  <a href="https://www.youtube.com/@vinitjain7">
                    <Youtube />
                  </a>
                </Button>
              </CardFooter>
            </Card> */}

            <h2 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold">How do I create a course?</h3>
                <p>
                  To create a course, click on the 'Create' button on the bottom left corner and
                  fill in the topic and time you have.
                </p>
              </div>
              <div>
                <h3 className="font-semibold">How can I reset my password?</h3>
                <p>
                  If you've forgotten your password, click on 'Forgot Password' on the login page
                  and follow the instructions.
                </p>
              </div>
              <div>
                <h3 className="font-semibold">How do I contact support?</h3>
                <p>
                  You can contact our support team by filling out the contact form below or emailing{' '}
                  {appVersion.email}
                  us at
                </p>
              </div>
            </div>
          </section>

          <section className="mb-12 w-full">
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <form className="space-y-4 w-full" onSubmit={onSubmit}>
              <div className="flex flex-col space-y-2 p-1">
                <Label htmlFor="name">Name</Label>
                <Input type="text" name="name" className={`p-2 border rounded-md`} />
              </div>
              <div className="flex flex-col space-y-2 p-1">
                <Label htmlFor="email">Email</Label>
                <Input type="email" name="email" className={`p-2 border rounded-md`} />
              </div>
              <div className="flex flex-col space-y-2 p-1">
                <Label htmlFor="message">Message</Label>
                <Textarea name="message" className={`p-2 border rounded-md`} rows="4" />
              </div>
              <Button type="submit" className="w-full mt-4">
                Send Message
              </Button>
              <span>{result}</span>
            </form>
          </section>
        </div>
      </div>

      <Footer />
    </ScrollArea>
  );
}

export default Help;
