import React from 'react';
import { SignInButton, SignUpButton } from '@clerk/clerk-react';
import { Link, useLocation } from 'react-router-dom';
import '../../styles/style.css';
import { useTranslation } from 'react-i18next';
import LanguageToggle from './LanguageToggle';

function Header() {
  const location = useLocation();
  const originalRequestUrl = location.pathname + location.search;
  const { t } = useTranslation();
  const appVersion = JSON.parse(process.env.REACT_APP_VERSION);

  return (
    <section
      id="top"
      className="my-3 bg-gray-100 px-2 py-1 border-2 rounded-full flex justify-between items-center text-pastel-green dark:text-pastel-white">
      <div className="flex items-center justify-start gap-2 pl-2">
        <Link to="/">
          <img src={appVersion.url} style={{ height: '38px', borderRadius: 10 }} alt="logo" />
        </Link>
        <h2>
          <Link
            to="/"
            className="font-thin text-xl"
            style={{
              fontFamily: 'Finger Paint, serif',
            }}>
            {appVersion.brand}
          </Link>
        </h2>
      </div>

      <div className="hidden md:flex space-x-8 py-2 px-4">
        {/* 'pricing', 'howItWorks', */}
        {['whatWeDo', 'faq'].map((key) => (
          <a
            key={key}
            className="hover:text-gray-600 transition custom-font"
            href={`#${t(`navigation.${key}`).replace(/ /g, '-').toLowerCase()}`}>
            {t(`navigation.${key}`)}
          </a>
        ))}
        <LanguageToggle />
      </div>

      <div className="hidden md:flex flex-row gap-1 md:gap-2">
        <SignUpButton
          mode="modal"
          fallbackRedirectUrl={originalRequestUrl}
          className="w-auto text-sm px-2 sm:px-4 py-2 bg-black text-white rounded-full hover:bg-zinc-700 font-semibold transition">
          {t('buttons.getStarted')}
        </SignUpButton>
        <SignInButton
          mode="modal"
          fallbackRedirectUrl={originalRequestUrl}
          className="w-auto text-sm px-2 sm:px-4 py-1 rounded-full bg-white hover:bg-gray-300 font-semibold transition">
          {t('buttons.signIn')}
        </SignInButton>
      </div>
    </section>
  );
}

export default Header;
