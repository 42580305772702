import { useState, useEffect } from 'react';
import { Send, Loader, ClipboardCheck, Video, BookOpen } from 'lucide-react';
import useChatStore from './useChatStore';

const mst1Prompts = [
  'Give me practice problems for MST-1 🎯',
  'What topics will be covered in MST-1? 📚',
  'Can you solve this practice problem from Chapter 3? 🔍',
  'Explain the concept of [topic] with examples 📝',
  'How do I solve questions about [specific topic]? 💡',
  'What are the important formulas to remember? ⚡',
  'Help me understand this [concept/formula] 🤔',
  'Can you explain the solution step by step? 📋',
  'Share some quick tips for MST-1 preparation 🎓',
  'What are common mistakes to avoid in MST-1? ⚠️',
];

const appVersion = JSON.parse(process.env.REACT_APP_VERSION);

const WelcomeCard = ({ course }) => {
  const {
    messages,
    inputMessage,
    selectedFile,
    setInputMessage,
    setSelectedFile,
    handleSendMessage,
    resetInput,
    isLoading,
  } = useChatStore();

  const sendMessage = (e) => {
    e.preventDefault();
    if (isLoading || !inputMessage.trim()) return; // Prevent sending empty or multiple messages
    handleSendMessage({
      course,
      inputMessage,
      selectedFile,
      ytLink: '',
      messages,
    });
    resetInput();
  };

  const [mediaType, setMediaType] = useState('chat');
  const mediaTypes = ['chat', 'document', 'image', 'video'];
  const [placeholder, setPlaceholder] = useState(mst1Prompts[0]);

  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      index = (index + 1) % mst1Prompts.length;
      setPlaceholder(mst1Prompts[index]);
    }, 4000);

    return () => clearInterval(timer);
  }, [mst1Prompts]);

  return (
    <div className="min-h-screen flex justify-center bg-brand/20 rounded-xl p-4">
      <div className="mt-10 bg-gradient-to-r from-background to-background/95 p-8 rounded-2xl max-w-2xl w-full shadow-lg h-fit">
        <div className="text-center mb-8">
          <img
            src={appVersion.url}
            alt="University Assistant Logo"
            className="mx-auto size-16 rounded-full mb-4"
          />
          <div className="text-2xl font-medium text-gray-900 dark:text-white mb-2">
            Welcome to Your Academic Assistant!
          </div>
          <p className="text-gray-500 dark:text-gray-400 text-sm max-w-md mx-auto">
            Your personal learning companion powered by AI.{' '}
            <span className="text-brand font-bold">
              I'm trained on your university's complete course materials
            </span>{' '}
            and ready to help with any questions you have about your studies.
          </p>
        </div>

        <div className="grid grid-cols-3 gap-4 mb-8">
          <div className="bg-gray-100 dark:bg-muted p-4 rounded-xl text-center">
            <BookOpen className="size-6 text-green-500 mx-auto mb-2" />
            <div className="text-gray-900 dark:text-white text-sm font-medium mb-1">Doc chat</div>
            <p className="text-gray-500 dark:text-gray-400 text-xs">
              Dive into course materials with detailed explanations and guidance.
            </p>
          </div>

          <div className="bg-gray-100 dark:bg-muted p-4 rounded-xl text-center">
            <ClipboardCheck className="size-6 text-green-500 mx-auto mb-2" />
            <div className="text-gray-900 dark:text-white text-sm font-medium mb-1">
              Homework Help
            </div>
            <p className="text-gray-500 dark:text-gray-400 text-xs">
              Get assistance in solving homework problems with step-by-step explanations.
            </p>
          </div>

          <div className="bg-gray-100 dark:bg-muted p-4 rounded-xl text-center">
            <Video className="size-6 text-green-500 mx-auto mb-2" />
            <div className="text-gray-900 dark:text-white text-sm font-medium mb-1">
              Video Summarization
            </div>
            <p className="text-gray-500 dark:text-gray-400 text-xs">
              Summarize educational videos into key points for quicker understanding.
            </p>
          </div>
        </div>

        <div className="flex gap-4 mb-6 text-gray-500 dark:text-gray-400">
          {mediaTypes.map((type) => (
            <button
              key={type}
              value={mediaType}
              onClick={() => setMediaType(type)}
              disabled
              // disabled={isLoading}
              className={`capitalize px-4 py-2 rounded-lg ${
                mediaType === type ? 'text-green-500 font-medium bg-gray-200 dark:bg-muted' : ''
              } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}>
              {type}
            </button>
          ))}
        </div>

        <div className="flex items-center bg-gray-100 dark:bg-muted rounded-xl p-2 transition-all duration-500">
          <div className="w-6 h-6 rounded-full mr-2">
            <img src={appVersion.url} alt="brogrammers-logo" />
          </div>
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage(e)}
            placeholder={placeholder}
            className="flex-1 outline-none bg-transparent text-gray-900 dark:text-white transition-all duration-300 placeholder-opacity-0 animate-fadeIn"
            disabled={isLoading}
          />
          <button
            onClick={sendMessage}
            disabled={isLoading}
            className={`w-8 h-8 rounded-lg flex items-center justify-center ${
              isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500'
            }`}>
            {isLoading ? (
              <Loader className="w-4 h-4 text-white animate-spin" />
            ) : (
              <Send className="w-4 h-4 text-white" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;
