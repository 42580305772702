import React from 'react';
import { Trash } from 'lucide-react';
import { useCourse } from '../../../context/useCourseContext';

function DeletedTopic({ unitId, title }) {
  const { user, course, updateCourse, setCourse, handleDeleteTopic } = useCourse();

  const deleteTopic = () => {
    const updatedUnits = course.units.map((unit) => {
      if (unit.id === unitId) {
        return {
          ...unit,
          topics: unit.topics.filter((topic) => topic.name !== title),
        };
      }
      return unit;
    });

    const updatedCourse = {
      ...course,
      units: updatedUnits,
    };
    handleDeleteTopic(title);
    updateCourse(updatedCourse);
    setCourse(updatedCourse);
  };
  return (
    <>
      {course.userId === user.id && (
        <button onClick={deleteTopic}>
          <Trash className="hover:text-slate-400 font-bold" size={18} />
        </button>
      )}
    </>
  );
}

export default DeletedTopic;
