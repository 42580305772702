import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircle, Loader2 } from 'lucide-react';
import { Header, InputFields, Upload, Files } from './CreateCourse';
import { Card, CardHeader, CardFooter, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { axios } from '../../services';
import { useCourse } from '../../context/useCourseContext';
import { useTranslation } from 'react-i18next';

const CourseCreation = () => {
  const { setCourse, user } = useCourse();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [courseType, setCourseType] = useState('ai');
  const [courseDetails, setCourseDetails] = useState({
    title: '',
    level: 'intermediate',
    depth: 'comprehensive',
    language: 'english',
  });
  const [files, setFiles] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };
  useEffect(() => {
    setFiles([]);
  }, [courseType]);

  const handleCreateCourse = async () => {
    if (!courseDetails.title.trim() || !courseDetails.level.trim() || !courseDetails.depth.trim()) {
      setError('All fields are required.');
      return;
    }
    setSubmitting(true);
    setError(null);

    const formData = new FormData();
    formData.append('userId', user.id);
    formData.append('title', courseDetails.title);
    formData.append('level', courseDetails.level);
    formData.append('depth', courseDetails.depth);
    formData.append('language', courseDetails.language);
    formData.append('course_type', courseType);
    formData.append('author', user.fullName);

    files.forEach((file) => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post('/course/new', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 900000,
      });
      setCourse(response.data.data);
      setSubmitting(false);
      setFiles([]);
      setCourseDetails({
        title: '',
        level: '',
        depth: '',
        language: '',
      });
      navigate(`/courses/${response.data.data?.title}?id=${response.data.data?._id}`);
    } catch (error) {
      console.error('Error submitting the form:', error);
      setError('Failed to create the course. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex mx-auto w-full">
      <div className="flex justify-center items-center">
        <Card className="w-full max-w-3xl bg-muted/40 p-6 space-y-4">
          <Header />
          <CardContent className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <InputFields
                  courseDetails={courseDetails}
                  handleInputChange={handleInputChange}
                  submitting={submitting}
                />
                {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
              </div>

              {/* Right Column */}
              <div className="space-y-4 pt-2">
                {/* Course Type Selection */}
                <div className="px-2">
                  <h3 className="text-sm font-medium pb-2">Course Type</h3>
                  <div className="flex gap-1">
                    {['ai', 'syllabus', 'doc'].map((type) => (
                      <Button
                        key={type}
                        size="sm"
                        variant={courseType === type ? 'default' : 'secondary'}
                        className={`w-full text-xs ${
                          courseType === type ? 'bg-brand hover:bg-brand-hover' : ''
                        }`}
                        onClick={() => setCourseType(type)}>
                        {type.toUpperCase()}
                      </Button>
                    ))}
                  </div>
                </div>

                {/* Upload and Files */}
                <div className="space-y-2 h-full">
                  <Upload
                    handleFileUpload={handleFileUpload}
                    submitting={submitting}
                    courseType={courseType}
                  />
                  <Files files={files} setFiles={setFiles} submitting={submitting} />
                </div>
              </div>
            </div>
          </CardContent>

          <CardFooter className="p-4">
            <Button
              className="bg-brand hover:bg-brand-hover w-full"
              disabled={submitting}
              onClick={handleCreateCourse}>
              {submitting ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <PlusCircle className="h-4 w-4" />
              )}
              <span className="ml-2">
                {submitting ? t('homePage.button.creating') : t('homePage.button.createCourse')}
              </span>
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default CourseCreation;
