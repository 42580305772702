import { motion, AnimatePresence } from 'framer-motion';
import Chat from './Chat';
import WelcomeCard from './WelcomeCard';
import useChatStore from './useChatStore';

function DocChat({ course }) {
  const { messages } = useChatStore();

  return (
    <>
      <AnimatePresence mode="wait">
        {messages.length > 1 ? (
          <motion.div
            key="chat"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.4 }}
            style={{ overflow: 'hidden', height: '100%' }}>
            <Chat course={course} />
          </motion.div>
        ) : (
          <motion.div
            key="welcomeCard"
            initial={{ opacity: 0, x: -50, height: 'auto' }}
            animate={{ opacity: 1, x: 0, height: 'auto' }}
            exit={{ opacity: 0, x: 50, height: 0 }}
            transition={{ duration: 0.4 }}
            style={{ overflow: 'hidden' }}>
            <WelcomeCard course={course} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default DocChat;
