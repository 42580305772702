import React, { useState } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/select';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../ui/card';
import { Button } from '../../ui/button';
import { axios } from '../../../services';
import { HelpCircle } from 'lucide-react';

const CourseSettings = ({ course }) => {
  const [depth, setDepth] = useState(course?.depth);
  const [level, setLevel] = useState(course?.level);
  const [language, setLang] = useState(course?.language);
  const [isLoading, setIsLoading] = useState(false);

  const isChanged =
    depth !== course?.depth || level !== course?.level || language !== course?.language;

  const updateCourse = async () => {
    if (!isChanged) return;

    setIsLoading(true);
    try {
      const data = { depth, level, language };
      await axios.patch(`/course/${course._id}`, data);
      alert('Settings updated successfully');
    } catch (error) {
      alert(`Failed to update settings: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="pb-1">Course Settings</CardTitle>
          <CardDescription>Customize course preferences</CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-x-2 flex items-center justify-between">
            <label className="w-1/2">Depth</label>
            <div className="p-1 w-full">
              <Select defaultValue={depth} onValueChange={(value) => setDepth(value)}>
                <SelectTrigger>
                  <SelectValue placeholder="Select depth" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="introductory">Introductory</SelectItem>
                  <SelectItem value="comprehensive">Comprehensive</SelectItem>
                  <SelectItem value="specialized">Specialized</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="space-x-2 flex items-center justify-between">
            <label className="w-1/2">Level</label>
            <div className="p-1 w-full">
              <Select defaultValue={level} onValueChange={(value) => setLevel(value)}>
                <SelectTrigger>
                  <SelectValue placeholder="Select level" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="beginner">Beginner</SelectItem>
                  <SelectItem value="intermediate">Intermediate</SelectItem>
                  <SelectItem value="advanced">Advanced</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="space-x-2 flex items-center justify-between">
            <label className="w-1/2">Language</label>
            <div className="p-1 w-full">
              <Select defaultValue={language} onValueChange={(value) => setLang(value)}>
                <SelectTrigger>
                  <SelectValue placeholder="Select language" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="english">English</SelectItem>
                  <SelectItem value="korean">Korean</SelectItem>
                  <SelectItem value="french">French</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex justify-end pt-4">
            <Button onClick={updateCourse} disabled={!isChanged || isLoading}>
              {isLoading ? 'Updating...' : 'Save Changes'}
            </Button>
          </div>
        </CardContent>
      </Card>
      <div className="flex items-start gap-2 px-2 mt-2 border-2 border-dashed rounded-lg py-2 bg-muted/40">
        <HelpCircle className="h-6 w-6 text-muted-foreground mt-1 flex-shrink-0" />
        <p className="text-sm text-muted-foreground">
          Changing the course depth, level, or language will require regenerating the topic material
          to reflect the updated settings. Otherwise, the topic material will remain unchanged.
        </p>
      </div>
    </>
  );
};

export default CourseSettings;
