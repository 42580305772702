import { useEffect, useRef } from 'react';
import { Send, Paperclip, Loader2 } from 'lucide-react';
import useChatStore from './useChatStore';
import MessageArea from './MessageArea';

const Chat = ({ course }) => {
  const {
    messages,
    inputMessage,
    selectedFile,
    isLoading,
    setInputMessage,
    setSelectedFile,
    handleSendMessage,
    resetInput,
  } = useChatStore();

  const bottomRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && ['application/pdf', 'text/plain', 'text/markdown'].includes(file.type)) {
      setSelectedFile(file);
    } else {
      alert('Only PDF, TXT, and MD files are supported.');
      resetInput();
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    handleSendMessage({
      course,
      inputMessage,
      selectedFile,
      ytLink: '',
      messages,
    });
    resetInput();
  };

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="flex flex-col h-full rounded-xl">
      <MessageArea messages={messages} isLoading={isLoading} bottomRef={bottomRef} />
      <div>
        <div className="space-y-2">
          <div className="flex items-center px-2 gap-3 rounded-xl border shadow-lg hover:shadow-xl">
            <label htmlFor="file-upload" className="p-2 rounded-lg hover:bg-muted cursor-pointer">
              <Paperclip className="w-5 h-5 text-gray-400" />
              <input
                id="file-upload"
                type="file"
                accept=".pdf,.txt,.md, .doc, .docx, .pptx"
                onChange={handleFileUpload}
                className="hidden"
              />
            </label>

            {selectedFile && <div className="text-sm text-gray-500">{selectedFile.name}</div>}

            <textarea
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && sendMessage(e)}
              placeholder="Ask a question about your document..."
              className="flex-1 px-2 py-3.5 bg-transparent outline-none overflow-auto"
            />

            <button
              onClick={sendMessage}
              disabled={isLoading}
              className="mr-2 p-2.5 rounded-lg bg-brand text-white hover:bg-brand-hover disabled:opacity-50">
              {isLoading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Send className="w-5 h-5" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
