import React, { useState } from 'react';
import { UploadCloud, X, FileIcon, HelpCircle } from 'lucide-react';
import { Switch } from '../../ui/switch';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../ui/card';
import { ScrollArea } from '../../ui/scroll-area';
import axios from '../../../services/axios';

const TrainCourse = ({ course }) => {
  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (files.length + selectedFiles.length > 10) {
      alert('You can upload a maximum of 10 files at a time.');
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleTraining = async () => {
    if (files.length === 0) {
      alert('Please upload at least one file before starting training.');
      return;
    }

    setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('userId', course.userId);
      formData.append('title', course.title);
      files.forEach((file) => formData.append('files', file));

      await axios.post(`/course/train/${course._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 90000,
        retries: 1,
      });
      alert('Completed training');
      setFiles([]);
    } catch (error) {
      console.error(error);
      alert(`Error training: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <ScrollArea className="h-full">
      <div className="max-w-3xl mx-auto space-y-4 pb-4">
        <Card className="w-full shadow-md rounded-xl">
          <CardHeader>
            <CardTitle className="text-lg">Train Course</CardTitle>
            <CardDescription>Manage course training settings</CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="flex items-center justify-between">
              <div className="space-y-1">
                <div className="font-medium">Dynamic Learning</div>
                <div className="text-sm text-muted-foreground">
                  Update knowledge base from conversations and flashcards
                </div>
              </div>
              <Switch disabled />
            </div>

            <div className="space-y-1">
              <div className="font-medium">Document Training</div>
              <div className="text-sm text-muted-foreground">
                Upload documents to enhance the AI's knowledge
              </div>
            </div>

            <div className="group border-2 border-dashed rounded-xl p-6 text-center hover:border-primary hover:bg-muted/50 transition">
              <Input
                disabled={submitting}
                type="file"
                id="file-upload"
                className="hidden"
                multiple
                accept=".pdf, .txt, .md, .docx, .doc, .pptx"
                onChange={handleInputChange}
              />
              <Label
                htmlFor="file-upload"
                className="cursor-pointer flex flex-col items-center gap-3">
                <UploadCloud className="h-12 w-12 text-primary/40 group-hover:text-primary transition" />
                <span className="font-medium text-muted-foreground">
                  Click or drag up to 10 PDFs to upload
                </span>
              </Label>
            </div>

            {files.length > 0 && (
              <ScrollArea className="h-40 border p-2 rounded-lg bg-muted">
                <div className="space-y-2">
                  {files.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-2 bg-white rounded-lg shadow-sm">
                      <div className="flex items-center gap-3">
                        <FileIcon className="h-5 w-5 text-muted-foreground" />
                        <div>
                          <p className="text-sm font-medium truncate max-w-[180px]">{file.name}</p>
                          <p className="text-xs text-muted-foreground">
                            {(file.size / 1024 / 1024).toFixed(2)} MB
                          </p>
                        </div>
                      </div>
                      <Button
                        variant="outline"
                        size="icon"
                        className="rounded-full"
                        onClick={() => removeFile(index)}>
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              </ScrollArea>
            )}

            <Button
              className="w-full"
              onClick={handleTraining}
              disabled={submitting || files.length === 0}>
              {submitting
                ? 'Training...'
                : `Start Training (${files.length} file${files.length !== 1 ? 's' : ''})`}
            </Button>
          </CardContent>
        </Card>

        <div className="flex items-start gap-2 px-4 py-3 border-2 border-dashed rounded-lg bg-muted/40">
          <HelpCircle className="h-6 w-6 text-muted-foreground flex-shrink-0" />
          <p className="text-sm text-muted-foreground">
            To verify training results, ask the chatbot a question about your document before and
            after training.
          </p>
        </div>
      </div>
    </ScrollArea>
  );
};

export default TrainCourse;
