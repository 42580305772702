import React from 'react';
import { BreadcrumbDemo } from './components';
import UserMenu from './components/UserMenu';
import { useCourse } from '../context/useCourseContext';
import CreditCoinSvg from '../assets/svg/credit_coin.svg';
import FireSvg from '../assets/svg/fire.svg';

const Topbar = () => {
  const { userStreak, userCredits } = useCourse();

  return (
    <div className="sticky top-0 flex justify-end md:justify-between items-center border-b border-gray-500 py-0.5 backdrop-blur-lg z-50">
      <div className="hidden sm:block sm:w-2/4 pl-1">
        <div className="overflow-hidden w-full">
          <BreadcrumbDemo />
        </div>
      </div>

      <div className="flex items-center sm:w-2/4 justify-end gap-2 pr-1">
        <div className="flex items-center gap-1 cursor-pointer border-2 rounded-2xl px-2 py-1 border-orange-500 bg-orange-600/20">
          <img src={FireSvg} alt="Streak" className="h-5 w-5" />
          <span className="text-sm font-medium">{userStreak}400</span>
        </div>

        <div className="flex items-center gap-1 cursor-pointer border-2 rounded-2xl px-2 py-1 border-yellow-500 bg-yellow-600/20">
          <img src={CreditCoinSvg} alt="Credits" className="h-5 w-5" />
          <span className="text-sm font-medium">{userCredits} 1200</span>
        </div>

        <UserMenu />
      </div>
    </div>
  );
};

export default Topbar;
