import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { axios } from '../../../services';
import { Send } from 'lucide-react';
import { ScrollArea } from '../../ui/scroll-area';
import { Textarea } from '../../ui/textarea';
import { Button } from '../../ui/button';
import { useUser } from '@clerk/clerk-react';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import MarkdownView from '../../MarkDownView';
import { AlertCircle, Heart, MessageSquare, Shield } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../../ui/alert';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const socket = io(BASE_URL);

const ChatRoom = ({ communityId }) => {
  const { user } = useUser();
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const welcomeMessageSeen = localStorage.getItem('welcomeMessageSeen');
    if (welcomeMessageSeen === 'true') {
      setIsVisible(false);
      getMsgs();
    }
  }, []);

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem('welcomeMessageSeen', 'true');
  };

  const getMsgs = async () => {
    try {
      const { data } = await axios.get(`/chat/${communityId}`);
      const newMessages = data.data || [];

      // Get stored messages from localStorage
      const storedMessages = JSON.parse(localStorage.getItem(communityId)) || [];

      // Merge without duplicates
      const allMessages = [...storedMessages];

      newMessages.forEach((newMsg) => {
        if (!allMessages.some((msg) => msg._id === newMsg._id)) {
          allMessages.push(newMsg);
        }
      });

      // Sort messages by timestamp
      allMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      // Update state and localStorage
      setMessages(allMessages);
      localStorage.setItem(communityId, JSON.stringify(allMessages));
    } catch (error) {
      console.log('Error fetching messages', error);
    }
  };

  // Ensure fetching messages on mount
  useEffect(() => {
    getMsgs();
  }, [communityId]);

  useEffect(() => {
    if (communityId) {
      socket.emit('joinRoom', communityId);
    }

    socket.on('receiveMessage', (data) => {
      console.log('New message received:', data);

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];

        if (!updatedMessages.some((msg) => msg._id === data._id)) {
          updatedMessages.push(data);
          updatedMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

          // Update localStorage
          localStorage.setItem(communityId, JSON.stringify(updatedMessages));
        }

        return updatedMessages;
      });
    });

    return () => {
      socket.off('receiveMessage');
    };
  }, [communityId]);

  const sendMessage = async () => {
    try {
      setMessage('');
      if (message.trim()) {
        const res = await axios.post(`/chat/${communityId}`, { sender: user.id, message });
      }
    } catch (error) {
      console.log('Error sending message', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <>
      {!isVisible ? (
        <div className="h-full flex flex-col bg-slate-50 dark:bg-zinc-800 text-gray-800 dark:text-gray-100 rounded-lg">
          <ScrollArea className="h-full">
            <div
              className={`p-4 ${
                messages.some(
                  (msg, index) => index > 0 && messages[index - 1]?.sender?._id !== msg?.sender?._id
                )
                  ? 'space-y-2'
                  : 'space-y-0'
              }`}>
              {messages?.map((msg, index) => {
                const isUserMessage = msg?.sender?._id === user?.id || msg?.sender === user?.id;
                const showHeader =
                  index === 0 ||
                  messages[index - 1]?.sender?._id !== msg?.sender?._id ||
                  new Date(msg?.timestamp).getTime() -
                    new Date(messages[index - 1]?.timestamp).getTime() >
                    300000;
                const userColor = stringToColor(msg?.sender?._id || msg?.sender);
                return (
                  <div
                    key={msg?._id || msg?.timestamp || Math.random()}
                    ref={msg === messages[messages.length - 1] ? scrollRef : null}
                    className={`group flex items-start gap-3 px-4 ${
                      showHeader && 'py-1'
                    } rounded-lg hover:bg-zinc-500/40`}>
                    <div className="w-14">
                      {showHeader ? (
                        <Avatar className={`size-12 rounded-full mt-1`}>
                          <AvatarImage className="rounded-full" />
                          <AvatarFallback
                            className="text-white rounded-full"
                            style={{ backgroundColor: userColor }}>
                            {msg?.sender?.username
                              ?.split('_')
                              ?.map((part) => part[0].toUpperCase())
                              ?.join('')}
                          </AvatarFallback>
                        </Avatar>
                      ) : (
                        <span className="text-xs text-muted-foreground w-full">
                          {formatTimestamp(msg?.timestamp)}
                        </span>
                      )}
                    </div>

                    <div className="flex-1 min-w-0">
                      {showHeader && (
                        <div className="relative flex items-center gap-2">
                          <span className="font-medium hover:underline cursor-pointer">
                            {msg?.sender?.username}
                          </span>
                          <span className="text-xs text-muted-foreground">
                            {formatTimestamp(msg?.timestamp)}
                          </span>
                        </div>
                      )}
                      <div className="relative">
                        <MarkdownView>{msg?.message}</MarkdownView>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollArea>

          <div className="border-t border-white px-2 py-2">
            <div className="mx-auto flex gap-3 items-center p-2">
              <Textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="message #help | #discuss | #notes"
                className="flex-1 min-h-[44px] max-h-[160px] resize-none border rounded-xl bg-muted-foreground/40"
                rows={1}
              />
              <Button
                onClick={sendMessage}
                disabled={!message.trim()}
                aria-label="Send message"
                className="size-10 rounded-xl p-0 flex items-center justify-center bg-brand">
                <Send className="w-5 h-5" />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-6 space-y-6 max-w-4xl mx-auto bg-muted/40 rounded-lg shadow-md">
          <Alert className="relative bg-brand/5 border-l-4 border-brand p-4 rounded-lg shadow-md">
            <AlertCircle className="size-5 text-brand" />
            <AlertTitle className="text-xl font-semibold">Welcome to Our Community!</AlertTitle>
            <AlertDescription className="mt-4 space-y-4">
              <p className="text-base text-muted-foreground">
                We're thrilled to have you as part of our community! To ensure a positive and
                respectful experience for everyone, we kindly ask you to take a moment to review our
                community guidelines.
              </p>

              <div className="grid gap-6 max-w-2xl">
                <div className="flex items-start gap-3">
                  <Heart className="size-8 text-brand mt-1" />
                  <div>
                    <h4 className="text-lg font-medium">Show Kindness</h4>
                    <p className="text-sm text-muted-foreground">
                      Treat others with respect and empathy. We have a zero-tolerance policy for
                      harassment or hate speech.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <MessageSquare className="size-8 text-brand mt-1" />
                  <div>
                    <h4 className="text-lg font-medium">Communicate Thoughtfully</h4>
                    <p className="text-sm text-muted-foreground">
                      Keep discussions constructive and relevant. Please avoid offensive language,
                      spamming, or inappropriate content.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <Shield className="size-8 text-brand mt-1" />
                  <div>
                    <h4 className="text-lg font-medium">Prioritize Safety</h4>
                    <p className="text-sm text-muted-foreground">
                      Keep personal information private. If you notice any inappropriate behavior,
                      kindly report it to our moderators.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-4">
                <Button onClick={handleDismiss} className="bg-brand hover:bg-brand/80 text-white">
                  Acknowledged, Thank You!
                </Button>
              </div>
            </AlertDescription>
          </Alert>
        </div>
      )}
    </>
  );
};

export default ChatRoom;
