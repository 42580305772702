import React from 'react';
import { useCourse } from '../../context/useCourseContext';
import { Topic, DocChat, KeyNotes, FlashCard, Quiz, Community } from './';
import Resources from './Resources';

function Right() {
  const { course, selectedTopic, selectedToggle } = useCourse();

  if (!course) return <div>Loading</div>;

  const renderContent = () => {
    if (selectedTopic) {
      return <Topic topic={selectedTopic} />;
    }

    switch (selectedToggle) {
      case 'docchat':
        return <DocChat course={course} />;
      case 'community':
        return <Community communityId={course.communityId} />;
      case 'quiz':
        return <Quiz course={course} />;
      case 'summary':
        return <KeyNotes keyNotes={course?.keyNotes} />;
      case 'resources':
        return <Resources course={course} />;
      case 'flashcard':
        return <FlashCard course={course} />;

      default:
        return (
          <div className="text-center font-semibold text-brand py-6">
            Please select a topic or toggle option.
          </div>
        );
    }
  };

  return (
    <div className="flex-1 h-full p-1">
      <div className="h-full overflow-hidden">{renderContent()}</div>
    </div>
  );
}

export default Right;
