import React from 'react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../ui/accordion';
import { ScrollArea } from '../../ui/scroll-area';

import { useCourse } from '../../../context/useCourseContext';
import AddTopic from './AddTopic';
import DeletedTopic from './DeletedTopic';

function Left() {
  const { course, selectedTopic, setSelectedTopic, setSelectedToggle, expanded } = useCourse();

  return (
    <div
      className={`${
        expanded ? 'w-full md:w-3/12' : 'w-0 opacity-0'
      } border-r-2 transition-all duration-50 h-full`}>
      <ScrollArea className="h-full px-2 pb-1">
        {course?.units?.length > 0 ? (
          course.units.map((unit) => (
            <Accordion key={unit?._id} type="single" collapsible>
              <AccordionItem key={unit?._id} value={`item-${unit.id}`}>
                <AccordionTrigger className="text-md text-left">{unit.title}</AccordionTrigger>
                {unit.topics.map((topic, topicIndex) => (
                  <AccordionContent
                    onClick={() => {
                      setSelectedToggle(null);
                      setSelectedTopic(topic);
                    }}
                    key={topicIndex}
                    className={`mb-1 p-2 rounded cursor-pointer ${
                      selectedTopic?._id === topic._id
                        ? 'bg-brand text-white '
                        : 'hover:bg-accent hover:text-foreground'
                    } transition-colors duration-100`}>
                    <div className="flex justify-between items-left group">
                      <h3 className="w-full">{topic?.name}</h3>
                      <div className="opacity-0 group-hover:opacity-100 transition-opacity">
                        <DeletedTopic title={topic} unitId={unit.id} />
                      </div>
                    </div>
                    <p
                      className={`font-thin text-xs ${
                        selectedTopic?._id === topic._id ? '' : 'text-muted-foreground'
                      } `}>
                      {topic?.cover}
                    </p>
                  </AccordionContent>
                ))}
                <AccordionContent>
                  <AddTopic unitId={unit._id} />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ))
        ) : (
          <p>No recently viewed courses available.</p>
        )}
      </ScrollArea>
    </div>
  );
}

export default Left;
