// App.jsx
import React from 'react';

function App() {
  return (
    <div className="max-w-3xl mx-auto p-8 min-h-screen bg-gray-50">
      <header className="mb-8">
        <h1 className="text-4xl font-bold mb-2">
          Hi there, <span className="text-purple-600">John</span>
        </h1>
        <h2 className="text-3xl font-semibold text-purple-700 mb-2">What would like to know?</h2>
        <p className="text-gray-600">
          Use one of the most common prompts below or use your own to begin
        </p>
      </header>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <PromptCard icon="👤" text="Write a to-do list for a personal project or task" />
        <PromptCard icon="✉️" text="Generate an email to reply to a job offer" />
        <PromptCard icon="💭" text="Summarise this article or text for me in one paragraph" />
        <PromptCard icon="⚙️" text="How does AI work in a technical capacity" />
      </div>

      <button className="flex items-center gap-2 text-gray-600 mb-6 hover:text-gray-800">
        <svg className="w-4 h-4" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"
          />
        </svg>
        Refresh Prompts
      </button>

      <div className="bg-white rounded-lg shadow-sm p-4">
        <input
          type="text"
          placeholder="Ask whatever you want...."
          className="w-full mb-4 p-2 focus:outline-none"
        />

        <div className="flex justify-between items-center">
          <div className="flex gap-4">
            <button className="text-gray-600 flex items-center gap-2 hover:text-gray-800">
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
              </svg>
              Add Attachment
            </button>
            <button className="text-gray-600 flex items-center gap-2 hover:text-gray-800">
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"
                />
              </svg>
              Use Image
            </button>
          </div>

          <div className="flex items-center gap-4">
            <select className="bg-gray-100 rounded-md px-3 py-1">
              <option>All Web</option>
            </select>
            <span className="text-gray-500 text-sm">0/1000</span>
            <button className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700">
              →
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function PromptCard({ icon, text }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer flex items-center gap-4">
      <span className="text-2xl">{icon}</span>
      <p className="text-gray-800">{text}</p>
    </div>
  );
}

export default App;
