import { create } from 'zustand';
import { axios } from '../../../services';

const useChatStore = create((set, get) => ({
  messages: [],
  inputMessage: '',
  ytLink: '',
  selectedFile: null,
  isLoading: false,
  context: '',
  chatSummary: '',
  inputType: 'chat',

  setInputMessage: (message) => set({ inputMessage: message }),
  setYtLink: (link) => set({ ytLink: link }),
  setSelectedFile: (file) => set({ selectedFile: file }),
  setIsLoading: (loading) => set({ isLoading: loading }),
  setContext: (context) => set({ context }),
  setChatSummary: (summary) => set({ chatSummary: summary }),
  setInputType: (type) => set({ inputType: type }),
  setMessages: (newMessages) => set((state) => ({ messages: [...state.messages, ...newMessages] })),

  resetInput: () => set({ inputMessage: '', ytLink: '', selectedFile: null, inputType: 'chat' }),

  handleSendMessage: async ({ course, inputMessage, ytLink, selectedFile, messages }) => {
    if (!inputMessage.trim() && !selectedFile && !ytLink.trim()) return;

    const userMessage = selectedFile
      ? `File: ${selectedFile.name}\n${inputMessage}`
      : ytLink
      ? `YouTube Link: ${ytLink}\n${inputMessage}`
      : inputMessage;

    // Add user's message to the state
    set((state) => ({
      messages: [...state.messages, { id: Date.now(), content: userMessage, sender: 'user' }],
      isLoading: true,
    }));

    try {
      const recentMessages = messages
        .slice(-3)
        .map((m) => `${m.sender}: ${m.content}`)
        .join(', ');

      const formData = new FormData();
      formData.append('courseId', course._id);
      formData.append('userId', course.userId);
      formData.append('title', course.title);
      formData.append('language', course.language);

      // Get current state using `get`
      const currentState = get();
      formData.append('history', `${currentState.chatSummary}\n${recentMessages}`);
      formData.append('context', currentState.context);
      formData.append('yt_link', ytLink);
      formData.append('input_type', selectedFile ? 'doc' : 'chat');

      if (selectedFile) formData.append('files', selectedFile);
      if (inputMessage.trim()) formData.append('input', inputMessage);

      const { data } = await axios.post('/chat/ai', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      set((state) => ({
        context: data.data.data.context || state.context,
        chatSummary: data.data.data.history || state.chatSummary,
        messages: [
          ...state.messages,
          { id: Date.now() + 1, content: data.data.output, sender: 'ai' },
        ],
      }));
    } catch {
      set((state) => ({
        messages: [
          ...state.messages,
          {
            id: Date.now() + 1,
            content: 'Sorry, there was an error processing your request.',
            sender: 'ai',
          },
        ],
      }));
    } finally {
      set({ isLoading: false, inputType: 'chat' });
    }
  },
}));

export default useChatStore;
