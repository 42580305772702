import Axios from '../../services/axios';
import MarkDownView from '../MarkDownView';
import { useCourse } from '../../context/useCourseContext';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';
import { Loading } from './Loading';
import YoutubeVids from './YoutubeVids';
import { useQuery } from '@tanstack/react-query';

function Topic({ topic }) {
  const { course } = useCourse();

  const fetchTopic = async () => {
    const courseData = { topic, ...course };
    const { data } = await Axios.post(`/course/topic/${course._id}`, courseData);
    return data.data;
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ['topic', topic],
    queryFn: fetchTopic,
    staleTime: Infinity,
  });

  if (isLoading) return <Loading />;
  if (isError) return <div className="text-red-500">Failed to load topic. Please try again.</div>;

  return (
    <ScrollArea className="h-full px-3 -mx-1 transition-all duration-300">
      <div className="flex-1 mt-1 pt-1 px-4 overflow-hidden bg-muted rounded-lg h-full">
        <div className="mb-10">
          <YoutubeVids
            videoIds={data?.suggestedVideos?.map((link) => link.split('/').pop()) || []}
          />
          <Separator />
          <MarkDownView size="default">{data?.markdownText || 'No content available'}</MarkDownView>
        </div>
      </div>
    </ScrollArea>
  );
}

export default Topic;
